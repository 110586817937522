import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../../components/main'
import { Helm } from '../../components/header'
import { Dropdown, Row, Col, Alert, Button, DropdownButton } from 'react-bootstrap'
import { cap } from '../../params'
import '../../style/style.css'
import '../../style/sty.scss'
import logoig from '../../assets/img/nasta/logoig.svg'
import burung from '../../assets/img/nasta/burung.svg'
import bunga6 from '../../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../../params'
import { Timer } from '../../components/timer'
import post from '../../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../../assets/img/logo.ico'
import covid from '../../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard';


let cmain = '#463F2D'
let black = 'rgb(38,38,38)'



let id = 'khairana-yudha'
let inisial_co = 'Yudha'
let inisial_ce = 'Khairana'
let lengkap_co = 'Arif Yudha Satria '
let lengkap_ce = 'Siti Khairana Ghassani '
let bapak_co = 'Bpk. Rusmono (alm)'
let ibu_co = 'Ibu Sukinem'
let bapak_ce = "Bpk. Irfan Luthana "
let ibu_ce = "Ibu Cut Wiwiek Iskandar"
let ig_co = "arifyudhasatria"
let ig_ce = "icutkhairana"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/16/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://g.page/amanavenue?share"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NzBrbzdnbm5nYXFtcWRpMnZmbThtM3JrM20gYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.371592424465!2d106.80943351476984!3d-6.345903195407762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ef41c8e80ac3%3A0xce770b70adcc1123!2sAMANA%20Venue!5e0!3m2!1sid!2sid!4v1609169600662!5m2!1sid!2sid"



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()
        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0,
            showQR: false,
            friend: 1
        }
    }

    componentDidMount() {
        AOS.init({
            duration: 2000
        });


    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }


                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    dropdown = () => {
        let { friend } = this.state
        let item = []
        for (let index = 1; index < 3; index++) {
            item[index] = (<Dropdown.Item onClick={() => {
                this.setState({ friend: index })
            }}>{index}</Dropdown.Item>)
        }
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px', color: cmain }} className="mb-0 w-100 text-center">
                        How many people you coming with?
          </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showrek } = this.state
        let slider = []
        let slide = [
            "ATM00056.jpg",
            "ATM00302.jpg",
            "ATM09190.jpg",
            "ATM09313.jpg",
            "ATM09332.jpg",
            "ATM09633.jpg",
            "ATM09668.jpg",
            "ATM09689.jpg",
            "ATM09760.jpg",
            "ATM09808.jpg",

        ]
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })


        let query = this.useQuery().get('u');

        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                />

                <div id='gold5' style={{
                    backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                    </h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Buka Undangan
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container fluid className="py-3" style={{backgroundColor:cmain}}>
                                    <Item>

                                        <p className="fs16 text-center px-3" style={{color:'white',fontSize:'16px'}}>
                                            Baarakallaahu laka wa baaraka ‘alaika wa jama’a bainakuma fii khaiir
<br /><br />
Semoga Allah memberkahimu dan memberkahi pernikahanmu, serta semoga Allah mempersatukan kalian berdua dalam kebaikan
</p>
                                    </Item>
                                </Container>
                                <Container className="dinny px-3 pt-5 ">




                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            Pernikahan <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>

                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw(id, "logoig.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '32px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={pw(id, "logoig.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaallah akan dilaksanakan pada:
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">16</span> JANUARI 2021
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: cmain }}>
                                            <b>Akad Nikah</b><br />
                                            <span className="cblack fs16">
                                                14.30 WIB- Selesai
                                            </span><br />
                                            <Item>
                                                <div className='p-3 mb-2 fs16' style={{ color: cmain, fontSize: '14px' }}>
                                                    Tanpa mengurangi rasa hormat, dikarenakan situasi pandemi saat ini, tidak memungkinkan bagi kami untuk mengundang Bapak/Ibu/Saudara/i untuk menghadiri akad nikah putra-putri kami serta memberikan doa restu secara langsung.<br />
Kami mengharapkan jarak tidak menjadi penghalang Bapak/Ibu/Saudara/i untuk tetap mengikuti prosesi pelaksanaan akad nikah putra-putri kami secara virtual. Untuk itu Bapak/Ibu/Saudara/i dapat menyaksikannya melalui tautan dibawah ini.
                                            </div>
                                            </Item>
                                            <Button style={{ backgroundColor: cmain }} onClick={() => {
                                                window.location.href = 'https://youtu.be/TbFzWyxP3Jw'
                                            }}>
                                                Live Streaming Akad
                                            </Button>

                                        </p>


                                    </Item>


                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic' }}>Undangan Hanya untuk 2 orang</p>
                                    </Item>

                                    <Item>

                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />
{/* 
                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw(id, "covid.svg")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container> */}


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                    <div className="p-3 mt-5">
                                        <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/vkDbqA5gP1o"></iframe>
                                        </div>
                                    </div>
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14' style={{ fontSize: '16px' }}>
                                                            Bantu kami mendokumentasikan apa yang telah dibagikan di sosial media dengan menambahkan #KHAINTSTOPLOVINGYUDH disetiap unggahan.

</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Kirimkan pesan dan doa
                    </h1>
                                                </Item>
                                                
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Kirimkan Pesan" name='pesan' />
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' />
                                                       
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                                <p style={{ fontSize: '16px', color: cmain }} className="w-100 text-center pt-3">
                                                    Bagi keluarga dan sahabat yang ingin mengirimkan doa, ucapan selamat, atau hadiah, silahkan mengirimkannya melalui tautan berikut:
                                                </p>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container fluid style={{ backgroundColor: cmain, color: 'white' }} className="py-3 mt-3">
                                <Item>
                                <Button style={{backgroundColor:cmain}} onClick={()=>{
                                                        this.setState({showrek:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px',fontFamily:'Tinos,serif'}}>
                                                           Tanda Kasih <img src='https://www.flaticon.com/svg/static/icons/svg/837/837891.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                </Item>
                                    {
                                        showrek?(<>
                                    <Item>
                                    <p className="text-center mx-5 py-3" style={{fontSize:'16px'}}>
                                    Tanpa mengurangi rasa hormat, bagi anda yang ingin memberikan tanda kasih untuk pengantin, dapat melalui:
                                    </p>
                                    </Item>
                                    <Item>
                                        
                                        <div className="p-3 px-5 col-md-4 col-12 my-2" style={{backgroundColor:'white'}}>
                                        <Item>
                                            <img src={pw(id,"bca.jpg")} style={{width:'200px',height:'200px'}} className="m-2"/>
                                        </Item>
                                            <Item>
                                            <p className="text-center" style={{fontSize:'16px',color:cmain}}>
                                                <b>Nomor Rekening 5245119167</b><br/>
                                                Bank BCA<br/>
                                                a.n. Siti Khairana Ghassani
                                            </p>
                                            </Item>
                                            <Item>
                                            <CopyToClipboard text="5245119167">
                                                    <div className="position-relative">
                                                    
                                                    <Button style={{backgroundColor:cmain}} onClick={()=>{
                                                        this.setState({copy:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px',color:'white'}}>
                                                            {this.state.copy?'Berhasil di Copy ke Clipboard':'Copy Nomor Rekening'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                                    
                                                   
                                                    </div>
                                                    </CopyToClipboard>
                                                </Item>

                                            </div>
                                    </Item>
                                    <Item>
                                        <div className="p-3 px-5 col-md-4 col-12 my-2" style={{backgroundColor:'white'}}>
                                        <Item>
                                            <img src={pw(id,"mandiri.jpg")} style={{width:'200px',height:'200px'}} className="m-2"/>
                                        </Item>
                                            <Item>
                                            <p className="text-center" style={{fontSize:'16px',color:cmain}}>
                                                <b>Nomor Rekening 1210005957265</b><br/>
                                                Bank Mandiri<br/>
                                                a.n. Arif Yudha Satria
                                            </p>
                                            </Item>
                                            <Item>
                                            <CopyToClipboard text="1210005957265">
                                                    <div className="position-relative">
                                                    
                                                    <Button style={{backgroundColor:cmain}} onClick={()=>{
                                                        this.setState({copy:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px',color:'white'}}>
                                                            {this.state.copy?'Berhasil di Copy ke Clipboard':'Copy Nomor Rekening'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                                    
                                                   
                                                    </div>
                                                    </CopyToClipboard>
                                                </Item>

                                            </div>
                                    </Item>
                                    <Item>
                                        <div className="p-3 px-5 col-md-4 col-12 my-2" style={{backgroundColor:'white'}}>
                                        <Item>
                                            <img src={pw(id,"bni.jpg")} style={{width:'200px',height:'200px'}} className="m-2"/>
                                        </Item>
                                            <Item>
                                            <p className="text-center" style={{fontSize:'16px',color:cmain}}>
                                                <b>Nomor Rekening 0573847001</b><br/>
                                                Bank BNI<br/>
                                                a.n. Arif Yudha Satria
                                            </p>
                                            </Item>
                                            <Item>
                                            <CopyToClipboard text="0573847001">
                                                    <div className="position-relative">
                                                    
                                                    <Button style={{backgroundColor:cmain}} onClick={()=>{
                                                        this.setState({copy:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px',color:'white'}}>
                                                            {this.state.copy?'Berhasil di Copy ke Clipboard':'Copy Nomor Rekening'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                                    
                                                   
                                                    </div>
                                                    </CopyToClipboard>
                                                </Item>

                                            </div>
                                    </Item>
                                    <Item>
                                        <div className="p-3 px-5 col-md-4 col-12 my-2" style={{backgroundColor:'white'}}>
                                        
                                            <Item>
                                            <p className="text-center" style={{fontSize:'16px',color:cmain}}>
                                            <b>Alamat :</b> <br/>
                                            Jl. Kecapi 1 no. 82 RT009/05 Jagakarsa Jakarta Selatan 12620
                                            </p>
                                            </Item>
                                            
                                            </div>
                                    </Item>
</>):false}
                                    </Container>
                                

                                <Foot ig={pw(id, "logoig.svg")} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

